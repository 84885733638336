import React from "react"
import styled from "styled-components"
import { Grid } from "./Layout"
import ImageBg from "./ImageBg"

const BannerWrapper = styled.section`
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40vh;

    .banner-title {
      color: var(--hlc-teal);
      text-transform: uppercase;
    }
  }
`

const Banner = ({ imageName, title }) => {
  return (
    <BannerWrapper id="SectionOne" className="section-banner">
      <ImageBg imageName={imageName} className="banner-image">
        <Grid>
          <div className="grid-content banner">
            <h1 className="banner-title">{title}</h1>
          </div>
        </Grid>
      </ImageBg>
    </BannerWrapper>
  )
}

export default Banner
