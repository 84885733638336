import React from "react"
import styled from "styled-components"
import { Grid } from "../Layout"
import { PurpleSection } from "../Elements"

const ApproachWrapper = styled(PurpleSection)`
  /* css goes here */
`

const AB01Approach = () => {
  return (
    <ApproachWrapper id="AB01Approach">
      <Grid>
        <div className="grid-content">
          <h1>Our Approach</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Earum
            consequuntur neque nostrum eos. Modi explicabo consequatur odit
            repudiandae mollitia nemo pariatur, repellat quisquam exercitationem
            neque in commodi quis magnam, eos officiis quam omnis! Est ut quasi
            consectetur minus vitae iusto?
          </p>
        </div>
      </Grid>
    </ApproachWrapper>
  )
}

export default AB01Approach
