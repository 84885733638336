import React from "react"
import { Layout, Head } from "../components/Layout"
import Banner from "../components/Banner"
import AB01Approach from "../components/About/AB01Approach"
import AB02Founders from "../components/About/AB02Founders"
import AB03HunterLily from "../components/About/AB03HunterLily"

const AboutPage = () => {
  return (
    <Layout>
      <Head title="About Us" />
      <Banner imageName="about_graded.jpg" title="About" />
      {/* <AB01Approach /> */}
      <AB02Founders />
      <AB03HunterLily />
    </Layout>
  )
}
export default AboutPage
