import React from "react"
import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const ImageBg = props => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName === props.imageName
      )
      if (!image) {
        return null
      }
      const stackedBG = [
        image.node.fluid,
        `linear-gradient(180deg, rgba(47, 52, 89, 0) 0%, rgba(47, 52, 89, 0.05) 5%, rgba(47, 52, 89, 0.6) 50%, #2f3459 85%, #2f3459 100%)`,
      ].reverse()

      return (
        <BackgroundImage
          fluid={stackedBG}
          className={props.className}
          style={props.styles}
          preserveStackingContext={true}
        >
          {props.children}
        </BackgroundImage>
      )
    }}
  />
)

export default ImageBg
