import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { Grid } from "../Layout"
import { WhiteSection } from "../Elements"

const FoundersWrapper = styled(WhiteSection)`
  .grid-content {
    display: flex;
    flex-direction: column;
    background: var(--hlc-white);

    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  }

  .info {
    h1 {
      color: var(--hlc-purple);
      text-transform: uppercase;
    }

    p {
      color: var(--hlc-purple);
    }
  }

  .founders-list {
    display: flex;
    padding: 0;
    list-style: none;
    text-align: center;

    .founder {
      padding: 1rem;

      a {
        cursor: pointer;
        text-decoration: none;
      }
      .headshot {
        width: 100%;
        clip-path: circle(50% at center);
      }

      .name {
        color: var(--hlc-teal);
        margin: 0;
      }
      .title {
        color: var(--hlc-purple);
        margin: 0;
      }
    }
  }
`

const AB02Founders = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulConsultants(filter: { type: { name: { eq: "Founder" } } }) {
        edges {
          node {
            name
            title
            slug
            headshot {
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <FoundersWrapper>
      <Grid>
        <div className="grid-content">
          <div className="info">
            <h1>Our Founders</h1>
            <p>
              Hunterlily & Co. was founded in 2017 by Alfred Chan and Ardie Wen.
            </p>
            <p>
              As Founders, Alfred and Ardie are responsible for championing the
              Hunterlily & Co. brand, as well as guiding the overall strategic
              direction of the business.
            </p>
          </div>
          <ul className="founders-list">
            {data.allContentfulConsultants.edges.map((edge) => {
              return (
                <li className="founder">
                  <Link to={`/about/team/${edge.node.slug}`} alt="name">
                    <img
                      className="headshot"
                      src={edge.node.headshot.fluid.src}
                      alt={edge.node.name}
                    />
                    <h3 className="name">{edge.node.name}</h3>
                    <h5 className="title">{edge.node.title}</h5>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </Grid>
    </FoundersWrapper>
  )
}

export default AB02Founders
